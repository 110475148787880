<template>
  <div id="body">
    <div class="fatherBox">

    </div>

    <div style="background-color: antiquewhite;">
        <el-form ref="form" :model="form" label-width="80px">
          <div style="display:flex;margin-top:20px;margin-left:30px;">
            <el-input v-model="form.StuName" placeholder="请输入姓名"  size="small" style="width:200px;"></el-input>
            <el-radio-group v-model="form.stuEditsex" style="margin: 11px 20px 0;">
              <el-radio    :label="1">男</el-radio>
              <el-radio    :label="0">女</el-radio>
            </el-radio-group>
          </div>
          <div class="search">
          <span>学校：</span>
           <el-select v-model="form.SchoolchuID" placeholder="请选择学校"  @change="Schoolchu">
             <el-option
               v-for="item in SchoolListt"
               :key="item.ID"
               :label="item.SchoolName"
               :value="item.ID"
             >
             </el-option>
           </el-select>
          </div>
        <div class="search">
        <span>年级：</span>
        <el-select v-model="form.GradechuID" placeholder="请选择年级"  @change="SchoolchuGrade">
         <el-option
            v-for="item in GradeList"
            :key="item.ID"
            :label="item.TypeName"
            :value="item.ID"
          >
          </el-option>
        </el-select>
        </div>
        <div class="search">
          <span>班级：</span>
        <el-select v-model="form.ClasschuID" placeholder="请选择班级"  @change="SchoolchuClass">
         <el-option
            v-for="item in ClassList"
            :key="item.ID"
            :label="item.ClassName"
            :value="item.ID"
          >
          </el-option>
        </el-select>
         </div>
        <el-button type="primary" @click="onSubmitchu">提交</el-button>
        </el-form>
    </div>
  </div>
</template>

<script>
  import {
    schoolList,
    excelSearch,
    gradeSearch,
    classSearch,
    studentAdd,
  } from '@/api/agentApi.js'
    import request from '@/api/request.js'
  export default {
    data() {
      return {//声明属性变量 可以用this.属性名得到 例如 this.pagesize
        form: {
          StuName: '', // 学生姓名
          stuEditsex: 1, // 学生性别默认男
          SchoolchuID: '', // 学校id
          GradechuID: '', // 年级id
          ClasschuID: '', // 班校id
        },
        SchoolInfos: [], // 学校列表
        SchoolID: '', // 数据导入学校
        serchList: {}, //查询列表
        schoolData: [], //学校集合
        SchoolListt: [],//学校集合
        GradeList: [],//年级集合
        ClassList: [],//班级集合
        token: localStorage.getItem('token'), //token
      }
    },
    mounted() {
      this.schoolList2() // 下拉框获取学校列表
      this.schoolExcelList()
    },
    methods: {
      schoolList2() { // 下拉框获取学校列表
        const data = {}
        data["page"] = 1 //	当前页数
        data["limit"] = 1000 // 每页条数
        schoolList(data).then(res => {
          this.schoolData = res.data.List.map((item, index) => {
            return Object.assign({}, {
              'schoolName': item.SchoolName
            }, {
              'id': item.ID
            })
          })
        }).catch(err => {
          console.log(err)
        })
      },
      schoolExcelList() {
        excelSearch().then(res => {
          //console.log(res)
            this.SchoolListt = res.data;
        }).catch(err => {
          console.log(err)
        })
      },

      Schoolchu (val) { // 数据导出学校选择
        this.form.SchoolchuID = val
          const data = {
            schoolID: this.form.SchoolchuID
          }
          this.form.GradechuID  ="";//清空年级
          this.form.ClasschuID  ="";//清空班级

          gradeSearch(data).then(res => {
              this.GradeList  = res.data;
          }).catch(err => {
            console.log(err)
          })
      },
      SchoolchuGrade (val) { // 年级选择
        //console.log(val);
        this.GradechuID = val
        const data = {
            gradeID: this.GradechuID
        }
        this.form.ClasschuID  ="";//清空班级
        classSearch(data).then(res => {
            this.ClassList  = res.data;
        }).catch(err => {
          console.log(err)
        })
      },
      SchoolchuClass (val) { // 班级选择
        this.ClasschuID = val
      },
      onSubmitchu() { //提交按钮
        if(this.form.StuName == '' ){
            this.$message.error(' 请填写  姓名');
            return false;
        }else if(this.form.SchoolchuID == '' ){
            this.$message.error(' 请选择  学校');
            return false;
        }else if(this.form.GradechuID == ''){
            this.$message.error(' 请选择  年级');
            return false;
        }else if(this.form.ClasschuID == ''){
            this.$message.error(' 请选择  班级');
            return false;
        }else{
            const data = {
              schoolID : this.form.SchoolchuID,
              gradeID  : this.form.GradechuID,
              classID  : this.form.ClasschuID,
              stuName  : this.form.StuName,
              stuSex   : this.form.stuEditsex,
            }
            studentAdd(data).then(res => {
              if(res.data == 2){
                this.$message.error('请勿重复添加')
              }else if (res.data > 0) {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                })
                this.form.stuEditsex = 1;
              } else {
                this.$message.error('服务器错误')
              }
                console.log(res)
            }).catch(err => {
              console.log(err)
            })
      }


      },
    }
  }
</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;

        div {
          width: 22%;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
</style>
